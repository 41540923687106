@import 'fontsData';
@import 'fonts';
@import 'vars';

:focus {
  outline: 1px dotted;
}

html, body {
  height: 100%;
  min-height: 100%;
  font-family: $font !important;
  color: white !important;
}

body {
  scrollbar-color: light;
  scrollbar-width: thin !important;
  /*scrollbar-width: none;*/
}

a {
  text-decoration: none !important;
}

* {
  scrollbar-width: thin !important;
  /*scrollbar-width: none;*/
}

.headless {
  max-width: 100vw !important;
  min-width: 100vw !important;

  header, app-player, app-navigation {
    display: none !important;
    position: inherit !important;
    width: 0 !important;
    height: 0 !important;
  }

  header, app-player, app-navigation, .main-container {
    min-width: auto !important;
    max-width: none !important;
    border: none;
  }

  .main-container {
    width: 100% !important;
  }

  .content-container {
    position: inherit !important;
    width: 100% !important;
    min-width: auto !important;
    max-width: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  #container-conter, #container-router, .content-container, .router-container {
    height: 100vh !important;
  }
}

.modal-background {
  left: 0;
  top: 0;
  height: 1000vh;
  width: 1000vw;
  position: fixed;
  background: rgba(0, 0, 0, 0.57);
  z-index: 105;
}

@keyframes changeBg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  background-color: black;
  background-image: url("../images/bgs/bg1.jpg");
  background-size: cover;
  overflow-y: hidden;

  .bg-container {
    background-size: cover;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 5s ease-in-out;
    opacity: 0;

    &.bg1 {
      opacity: 1;
    }

    &.bg2 {
      opacity: 1;
    }

    &.bg3 {
      opacity: 1;
    }

    &.bg4 {
      opacity: 1;
    }

    &.bg5 {
      opacity: 1;
    }

    &.bg6 {
      opacity: 1;
    }

    &.bg7 {
      opacity: 1;
    }

    &.bg8 {
      opacity: 1;
    }
  }

  &.bg1first, .bg-container.bg1first {
    background-image: url("../images/bgs/bg1.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg1, .bg-container.bg1 {
    background-image: url("../images/bgs/bg1.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg2, .bg-container.bg2 {
    background-image: url("../images/bgs/bg2.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg3, .bg-container.bg3 {
    background-image: url("../images/bgs/bg3.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg4, .bg-container.bg4 {
    background-image: url("../images/bgs/bg4.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg5, .bg-container.bg5 {
    background-image: url("../images/bgs/bg5.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg6, .bg-container.bg6 {
    background-image: url("../images/bgs/bg6.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg7, .bg-container.bg7 {
    background-image: url("../images/bgs/bg7.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  &.bg8, .bg-container.bg8 {
    background-image: url("../images/bgs/bg8.jpg") !important;
    animation: changeBg 5s ease none;
    animation-fill-mode: initial;
  }

  .peek-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 500%;
    width: 100%;
    background: rgba(0, 0, 0, 0.57);
    z-index: 100;
  }

  #container-content {
    height: $mainContainerHeight;

    &.with-external-header {
      height: $mainContainerWithTopBarHeight !important;

      #container-router {
        height: $mainContainerWithTopBarHeight !important;
      }
    }

    /*&.with-header {
      position: relative;
      height: 100%;
      top: -48px;
      !*background-color: $backDark;*!
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      #container-router {
        padding-top: 48px;
      }
    }*/

    #container-router {
      height: $mainContainerHeight;
      width: calc(100%); /* + 15px*/
      /*overflow: auto;
      overflow-x: hidden;*/
    }
  }

  .inner-component-container {
    margin-left: 0;
  }

  /*This will work on every browser but Chrome Browser*/
  .fixed-table-header thead {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 8;
    background-color: $backDark;
  }

  /*This will work on every browser*/
  .chrome .fixed-table-header thead th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 8;
    background-color: $backDark;
  }

  .fixed-div {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 8;
    background-color: $backDark;
  }

  .fixed-table-header-virtual-scroll thead th {
    background-color: $backDark;
  }

  /*This will work on every browser*/
  /*.fixed-table-header-virtual-scroll thead th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 8;
    background-color: $backDark;
  }*/
}

.content-container {
  /* width */
  ::-webkit-scrollbar {
    /*width: 0;*/
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: $borderFrame;
    //border-top-right-radius: 6px;
    //border-bottom-right-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: $backAlmostDark;
    border-radius: 4px;
    border-left: 1px solid $borderFrame;
    border-right: 1px solid $borderFrame;
  }
}

.no-scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  /*&::-webkit-scrollbar-track {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }*/

  /* Handle */
  /*&::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
    border: none;
  }*/
}

.pointer {
  cursor: pointer;
}

.link-active-white {
  cursor: pointer !important;
  color: white !important;
  text-decoration: underline !important;
}

.link-disabled {
  cursor: default !important;
  color: grey !important;
  text-decoration: none !important;

  &:hover {
    color: grey !important;
    text-decoration: none !important;
  }
}

a {
  text-decoration: none;
  color: $lightBlue;

  &:active,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $lightBlueActive;
  }

  &.more-button {
    font-size: 13px;
    color: $lightBlue;
    margin-left: 20px;
    padding-left: 20px;
    position: absolute;
    right: 0px;
    bottom: 0;
    // background: -moz-linear-gradient(left,  rgba(30,87,153,0) 0%, rgba(6,23,23,0.56) 10%, rgba(6,23,23,1) 18%, rgba(6,23,23,1) 21%, rgba(6,23,23,1) 99%, rgba(6,23,23,1) 100%);
    // background: -webkit-linear-gradient(left,  rgba(30,87,153,0) 0%,rgba(6,23,23,0.56) 10%,rgba(6,23,23,1) 18%,rgba(6,23,23,1) 21%,rgba(6,23,23,1) 99%,rgba(6,23,23,1) 100%);
    // background: linear-gradient(to right,  rgba(30,87,153,0) 0%,rgba(6,23,23,0.56) 10%,rgba(6,23,23,1) 18%,rgba(6,23,23,1) 21%,rgba(6,23,23,1) 99%,rgba(6,23,23,1) 100%);

    &:hover {
      color: $lightBlueActive;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 0;
}

th, th a {
  font-weight: 100 !important;
}

/* FORM GENERAL STYLES */
input[type=text], input[type=email], input[type=password] {
  border: 1px solid $lightBlue;
  background: $backDark;
  color: #fff;

  &::-webkit-input-placeholder {
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
  }

  &:-ms-input-placeholder {
    color: #fff;
  }

  &.has-error {
    border: 1px solid $alert;
    outline: none;
  }
}

.flex-wrapper {
  display: flex;
}

.clickable {
  cursor: pointer;
}

/*   PLAY BUTTONS   */

.small-play {
  background: url('/assets/images/play.svg');
  background-repeat: no-repeat;
  background-size: 21px 21px;
  border: 0;
  box-shadow: none;
  margin: 10px;
  display: inline-block;
  vertical-align: middle;
  height: 21px;
  width: 21px;
  outline: none;


  &:hover {

  }

  &.paused, &.loading {
    background: url('/assets/images/play-ready.svg');
    background-size: 21px 21px;
  }

  &.playing, &.active {
    background: url('/assets/images/pause.svg');
    background-size: 21px 21px;
  }

  &.no-margins {
    margin: 0;
  }

  &.blocked, &.disabled {
    opacity: 0.3;
  }
}

.medium-play {
  background: url('/assets/images/play.svg');
  background-repeat: no-repeat;
  background-size: 28px 28px;
  border: 0;
  box-shadow: none;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  width: 28px;
  outline: none;


  &:hover {

  }

  &.paused, &.loading {
    background: url('/assets/images/play-ready.svg');
    background-size: 28px 28px;
  }

  &.playing, &.active {
    background: url('/assets/images/pause.svg');
    background-size: 28px 28px;
  }

  &.no-margins {
    margin: 0;
  }

  &.blocked, &.disabled {
    opacity: 0.3;
  }
}


.big-play {
  background: url('/assets/images/play.svg') no-repeat;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  border: 0;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  height: 34px;
  width: 34px;
  outline: none;


  &:hover {

  }

  &.paused, &.loading {
    background: url('/assets/images/play-ready.svg') no-repeat;
    background-size: 34px 34px;
  }

  &.playing, &.active {
    background: url('/assets/images/pause.svg') no-repeat;
    background-size: 34px 34px;
  }

  &.no-margins {
    margin: 0;
  }

  &.blocked, &.disabled {
    opacity: 0.3;
  }
}


.blocked, .disabled {
  opacity: 0.5;
}

.white-link {
  cursor: pointer;
  color: #c5c5c5;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #c5c5c5;
    text-decoration: underline;
  }
}

.link-like {
  cursor: pointer;
  display: flex;
}

.sticky-div {
  position: sticky;
  top: 0px;
}

.valign-children {
  display: flex;
  align-items: center;
}

.about-side-bar {
  .image-box {
    margin-bottom: 30px;
  }

  .caption {
    color: $fontHighlighted;
    font-size: 17px;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  a {
    text-decoration: none !important;
    color: $fontHighlighted !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.about-page {

  margin-top: 20px;

  .light-blue {
    color: $fontHighlighted;
  }

  a {
    text-decoration: underline;
    color: $grey;

    &:hover {
      color: $fontHighlighted;
    }
  }

  h3 {
    color: $fontHighlighted;
  }

  .about-menu-container {
    h3 {
      margin-top: 25px;
      margin-bottom: 25px;
      text-transform: uppercase;
      font-size: 20px;
    }
  }

  ul.about-menu {
    li {
      text-decoration: underline;

      a {
        font-size: 20px;
      }
    }
  }

  .interview-question {
    color: $fontHighlighted !important;
  }

  .interview-answer {
    color: $grey !important;
  }
}

@media (max-width: $mainWidth) {

  // override bootstrap classes to limit responsive less than 1280px
  .col-lg-1, .col-lg-2, .col-lg-3,
  .col-lg-4, .col-lg-5, .col-lg-6,
  .col-lg-7, .col-lg-8, .col-lg-9,
  .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }

}

.generic-page-text-container {
  color: $fontGrey;
  font-size: 18px;

  .align-center {
    text-align: center;
  }

  .text-smaller {
    font-size: 16px;
  }

  .top-margin-medium {
    margin-top: 16px;
  }

  .bottom-margin-medium {
    margin-bottom: 16px;
  }

  h1, h2, h3, h4, h5 {
    color: $lightBlue;
  }

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: 1.1;
  }

  a, a:active, a:visited {
    color: $lightBlue;
  }

  a:hover {
    color: $lightBlueActive;
  }
}

.hos-tooltip {
  opacity: 0.9 !important;
}

.toast-container {
  margin-bottom: 150px !important;
  z-index: 200;
  position: absolute;
  top: 80px;
  right: 50%;
  transform: translate(50%, 0%);
  -webkit-box-shadow: 6px 3px 35px -6px rgba(0,0,0,0.64);
  -moz-box-shadow: 6px 3px 35px -6px rgba(0,0,0,0.64);
  box-shadow: 6px 3px 35px -6px rgba(0,0,0,0.64);
  pointer-events: auto !important;
}

.ngx-toastr {
  margin-bottom: 15px !important;
  padding: 50px;
  border: 1px solid #3BBDBA;
}

.toast-success {
  background-color: $backSemiDark;
  opacity: 0.95 !important;
}

.toast-error {
  background-color: $alert;
  opacity: 0.9 !important;
  margin-bottom: 150px !important;
}

.toast-close-button {
  position: absolute;
  right: 10px;
  top: 0;
  background: transparent;
  border: 0;
  color: white;
  font-size: 25px;
}

.app-modal {
  position: absolute;
  top: 100px;
  left: calc(50% - 220px);
  z-index: 106;
  background-color: rgba(6, 23, 23, 0.88);
  border: 2px solid $lightBlue;
  border-radius: 8px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  width: 440px;

  .modal-title {
    font-family: $font;
    font-size: 18px;
    line-height: 27px;
    color: $lightBlue;
    padding: 30px 30px 0 30px;
  }

  .modal-body {
    font-family: $font;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    padding: 30px;
    overflow: auto;
  }

  .modal-body a {
    color: $lightBlue !important;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .modal-actions {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

img.share-image-button {
  width: 12px;
  margin-right: 6px;
}

.image-button {
  img {
    width: 12px;
    margin-right: 6px;
  }
}

/* radio buttons */

.hos-radio-button {
  input[type=radio] {
    display: none;

    &:checked + label:before {
      background: $lightBlue;
    }
  }

  input[type=radio] + label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    font-size: 16px;
    color: $lightBlue;
    margin-bottom: 10px;

    &:before {
      content: "";
      display: inline-block;

      width: 16px;
      height: 16px;

      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 4px;
      border-radius: 8px;
      border: 1px solid $lightBlue;
    }
  }
}

/* check boxes */

.hos-checkbox {
  input[type=checkbox] {
    display: none;

    &:checked + label:before {
      background: #194b4b;
    }

    &:checked + label:after {
      content: url("/assets/images/checked.png");
      display: inline-block;
      position: absolute;
      left: -1px;
      top: 2px;
    }

  }

  input[type=checkbox] + label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    font-size: 16px;
    color: $lightBlue;
    margin-bottom: 10px;

    &:before {
      content: "";
      display: inline-block;

      width: 16px;
      height: 16px;

      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 4px;
      border-radius: 4px;
      border: 1px solid $lightBlue;
    }

    &.error {
      color: $error !important;

      &:before {
        border-color: $error !important;
      }

      a {
        color: $error !important;
      }
    }
  }

  input[type=checkbox] + label.hos-checkbox-label {
    font-size: 14px;
    color: #c5c5c5;
    line-height: 24px;
  }

  .hos-checkbox-label {
    a, a:hover {
      color: #50e3c2;
      text-decoration: underline !important;
    }
  }
}

/* modal media queries */

@media (max-height: 650px) {
  .app-modal {
    top: 0;
    transform: scale(0.75);
    transform-origin: top;
  }
}

@media (min-height: 651px) and (max-height: 700px) {
  .app-modal {
    top: 0;
    transform: scale(0.8);
    transform-origin: top;
  }
}

@media (min-height: 701px) and (max-height: 750px) {
  .app-modal {
    top: 0;
    transform: scale(0.85);
    transform-origin: top;
  }
}

@media (min-height: 751px) and (max-height: 800px) {
  .app-modal {
    top: 10px;
    transform: scale(0.9);
  }
}

@media (min-height: 801px) and (max-height: 900px) {
  .app-modal {
    top: 50px;
  }
}

@media (max-width: 479px) {
  .app-modal {
    width: 380px;
    left: calc(50% - 190px);
  }
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
//@import "~ngx-bootstrap/datepicker/bs-datepicker";

.peek-wrapper {
  display: inline-block;
}

tr {
  p {
    margin: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:focus {
  background-color: $backDark;
}

.hidden {
  display: none!important;
}
